import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Fab,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Search } from "@mui/icons-material";
import clickOnceWrapper from "../helpers/clickOnceWrapper";
import toFixedIfNecessary from "../helpers/toFixedIfNecessary";

const CreateReturnDialog = ({ addModal, setAddModal, jobs, saveReturn }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={addModal.open}
      onClose={() => {
        setAddModal({
          open: false,
          search: "",
          imageBlobs: [],
          imageUrls: [],
          items: [{ item_text: "", quantity: 0 }],
          jobId: null,
        });
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          overflow: "scroll",
          padding: 16,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          Add New Return
        </Typography>

        <div>
          <Typography
            id="modal-modal-title"
            variant="p"
            sx={{ width: "100%", textAlign: "start", fontWeight: "bold" }}
          >
            Select Job
          </Typography>
          <FilledInput
            style={{ marginTop: 16, width: "100%" }}
            type="text"
            hiddenLabel={true}
            value={addModal.search}
            placeholder="Search Job Name"
            onChange={(e) => {
              setAddModal((prev) => ({ ...prev, search: e.target.value }));
            }}
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
          />
          <div
            style={{
              display: "block",
              maxHeight: 100,
              maxWidth: "100%",
              background: "#d2d2d2",
              overflowX: "hidden",
            }}
          >
            {jobs.map((job, index) => (
              <div
                style={{
                  padding: 8,
                  maxWidth: "100%",
                  background: job.id === addModal.jobId ? "#000" : null,
                  color: job.id === addModal.jobId ? "#fff" : null,
                }}
                key={index}
                onClick={() => {
                  setAddModal((prev) => ({
                    ...prev,
                    jobId: job.id,
                    //selectedPo: null,
                  }));
                }}
              >
                <p style={{ margin: 0, fontWeight: "bold", fontSize: 16 }}>
                  {job.projectid} - {job.job_name}
                </p>
                <p
                  style={{
                    margin: 0,
                    fontSize: 12,
                    color:
                      job.id === addModal.jobId ? "#fff" : "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  {job.address}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="p"
            sx={{ width: "100%", textAlign: "left", fontWeight: "bold" }}
          >
            Items
          </Typography>
          <div style={{ width: "100%" }}>
            {addModal.items &&
              addModal.items.map((item, index) => (
                <div
                  key={index}
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    sx={{ flex: 1 }}
                    name="Description"
                    label="Description"
                    type="text"
                    value={item.item_text}
                    onChange={(e) => {
                      let val = e.target.value;
                      let itemsCopy = [...addModal.items];
                      itemsCopy[index].item_text = val;
                      setAddModal((prev) => ({
                        ...addModal,
                        items: itemsCopy,
                      }));
                    }}
                  />
                  <TextField
                    sx={{ width: "80px", marginLeft: "8px" }}
                    name="Quantity"
                    label="Quantity"
                    type="number"
                    value={item.quantity}
                    // onChange={(e) => {
                    //   let val = e.target.value;
                    //   let itemsCopy = [...addModal.items];
                    //   itemsCopy[index].item_text = val;
                    //   setAddModal((prev) => ({
                    //     ...addModal,
                    //     items: itemsCopy,
                    //   }));
                    // }}
                    onChange={(e) => {
                      let val;
                      if (
                        e.target.value == "" ||
                        e.target.value === undefined ||
                        e.target.value === null ||
                        e.target.value === "0" ||
                        e.target.value === "00"
                      ) {
                        val = 0;
                      } else {
                        val = e.target.value.replace(/^0+/, "");
                        val = toFixedIfNecessary(val, 2);
                      }
                      // Do Not Allow Negative
                      if (val < 0) {
                        val = 0;
                      }
                      //   setScannedItem((prev) => ({
                      //     ...prev,
                      //     quantity: val,
                      //   }));

                      let itemsCopy = [...addModal.items];
                      itemsCopy[index].quantity = val;
                      setAddModal((prev) => ({
                        ...addModal,
                        items: itemsCopy,
                      }));
                    }}
                  />
                  <Button
                    sx={{}}
                    variant="text"
                    onClick={() => {
                      let itemsCopy = [...addModal.items];
                      itemsCopy.splice(index, 1);
                      setAddModal((prev) => ({
                        ...prev,
                        items: itemsCopy,
                      }));
                    }}
                  >
                    Remove
                  </Button>
                </div>
              ))}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{ marginTop: "15px", width: 150 }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                let itemsCopy = [...addModal.items];
                itemsCopy.push({ item_text: "", quantity: 0 });
                setAddModal((prev) => ({
                  ...prev,
                  items: itemsCopy,
                }));
              }}
            >
              Add Item
            </Button>{" "}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            overflowY: "auto",
            width: isSmallScreen ? "300px" : "550px",
            margin: "0 auto 20px 0",
            backgroundColor: "#cacbcc",
            padding: "8px",
            marginTop: 16,
          }}
        >
          <label>
            <input
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              name="images"
              capture="environment"
              onChange={(event) => {
                console.log("FILE: ", event.target.files[0]);
                console.log(
                  "Image",
                  URL.createObjectURL(event.target.files[0])
                );
                const file = event.target.files[0];
                setAddModal((prev) => ({
                  ...prev,
                  imageBlobs: [...prev.imageBlobs, file],
                  imageUrls: [
                    ...prev.imageUrls,
                    URL.createObjectURL(event.target.files[0]),
                  ],
                }));
              }}
            />
            <div
              style={{
                cursor: "pointer",
                width: "100%",
                padding: 12,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {addModal.imageUrl ? (
                <img
                  src={addModal.imageUrl}
                  style={{ marginTop: 16, maxWidth: "100%", maxHeight: 200 }}
                />
              ) : (
                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#fff",
                    backgroundColor: "#000",
                    color: "#fff",
                    width: 100,
                    height: 100,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AddIcon sx={{ fontSize: 40 }} />

                  <p style={{ marginTop: "0px" }}>Photo</p>
                </div>
              )}
            </div>
          </label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              overflowY: "auto",
              width: "300px",
              margin: "0 auto 20px 0",
              backgroundColor: "#cacbcc",
            }}
          >
            {addModal.imageUrls.length > 0 &&
              addModal.imageUrls.map((url) => (
                <img
                  src={url}
                  style={{
                    minHeight: "100px",
                    maxHeight: "100px",
                    minWidth: "100px",
                    maxWidth: "100px",
                    marginRight: "3px",
                    border: "1px solid black",
                  }}
                />
              ))}
          </div>
        </div>

        <FormControl fullWidth style={{ marginTop: 16 }}>
          <TextareaAutosize
            placeholder="Notes..."
            minRows={3}
            style={{ width: "100%" }}
            value={addModal.notes}
            onChange={(e) => {
              setAddModal((prev) => ({
                ...prev,
                notes: e.target.value,
              }));
            }}
          />
        </FormControl>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            // disabled={addModal.jobId === null}
            variant="outlined"
            onClick={async () => {
              setAddModal({
                open: false,
                search: "",
                imageBlobs: [],
                imageUrls: [],
                items: [{ item_text: "", quantity: 0 }],
                jobId: null,
              });
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{ marginLeft: "8px" }}
            // disabled={addModal.jobId === null}
            variant="contained"
            onClick={clickOnceWrapper(() => {
              saveReturn();
            })}
          >
            Submit
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateReturnDialog;
