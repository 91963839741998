import { useContext, useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Inventory, Logout, PanTool, PeopleOutline } from "@mui/icons-material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BasicTabs from "../../component/Tabs";
import { Outlet } from "react-router-dom";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { GrabsContext } from "../../context/GrabsContext";
import { TopNavContext } from "../../context/TopNavContext";

import boneyardIcon from "../../assets/boneyard.png";
import committedIcon from "../../assets/committed.png";
import inventoryIcon from "../../assets/inventory.png";
import stagedIcon from "../../assets/staged.png";
import toolsIcon from "../../assets/tools.png";
import HomeIcon from "@mui/icons-material/Home";

import { FaHandRock } from "react-icons/fa";
import SnackAlert from "../../component/SnackAlert";
import { SnackAlertContext } from "../../context/SnackAlertContext";
import ReturnToHome from "../../component/ReturnToHome";

import behmerLogo from "../../assets/behmer_logo.png";
import nailIcon from "../../assets/nail_icon.png";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      display: "none",
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function DashboardContent() {
  const {
    openSnackAlert,
    setOpenSnackAlert,
    snackAlertSeverity,
    snackAlertMessage,
  } = useContext(SnackAlertContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { username, fullName, signOut } = useContext(AuthContext);
  const { grabCount } = useContext(GrabsContext);
  const { pageName } = useContext(TopNavContext);
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // for hamburger menu
  const [anchorEl, setAnchorEl] = useState(null);
  const hamburgerOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open} sx={{ boxShadow: "none" }}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <Box
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr auto 1fr",
                alignItems: "center",
                width: "100%",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Box
                sx={{
                  marginRight: "auto",
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => navigate(-1)}
                  sx={{
                    marginRight: "auto",
                    ...(open && { display: "none" }),
                  }}
                >
                  <KeyboardBackspaceIcon fontSize="large" />
                </IconButton>
              </Box>

              {/* <Typography variant="h5">{pageName}</Typography> */}
              <Box
                sx={
                  {
                    // display: "flex",
                    // border: "2px solid green",
                    // alignSelf: "center",
                    // justifySelf: "center",
                    // position: "absolute",
                    // top: 10,
                    // left: "50%",
                  }
                }
              >
                <img
                  src={behmerLogo}
                  alt="Behmer Roofing Logo"
                  style={{
                    maxWidth: "100px",
                    maxHeight: "75px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/home")}
                />
              </Box>

              <Box
                sx={{
                  marginLeft: "auto",
                }}
              >
                <IconButton
                  style={{ color: "#fff" }}
                  onClick={() => navigate("/home")}
                >
                  <HomeIcon />
                </IconButton>
                <IconButton
                  style={{
                    marginLeft: "8px",
                    color: "#fff",
                    hover: {
                      color: theme.palette.secondary.main,
                    },
                  }}
                  id="burger-button"
                  aria-controls={hamburgerOpen ? "burger-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={hamburgerOpen ? "true" : undefined}
                  onClick={handleClick}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={hamburgerOpen}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "burger-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate(`/tools`);
                    }}
                  >
                    Tools
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate(`/`);
                      signOut();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
            {/* 
              So basically every one of the navigation tabs has increased in length:
              Jobs --> Job material history, materials --> inventory, pulls --> stock-outs, pos --> pending pos.
              This doesn't work on mobile. So we are removing the tabs in favor of the home page tiles
            */}
            {/* <Box sx={{ display: "flex", flexDirection: "row" }}>
              <BasicTabs />
            </Box> */}
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                minHeight: "50px",
                width: "100vw",
              }}
            >
              <Typography variant="h5" color="black" textAlign="center">
                {pageName}
              </Typography>
            </Box> */}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {/* <ListItemButton
            onClick={() => {
              navigate(`/release`);
            }}
          >
            <ListItemIcon>
              <Inventory sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary={<Typography>Material Release</Typography>} />
          </ListItemButton> */}
          <ListItemButton
            onClick={() => {
              navigate(`/pulls`);
            }}
          >
            <ListItemIcon>
              <FaHandRock style={{ color: "black", marginLeft: 2 }} size={20} />
            </ListItemIcon>
            <ListItemText primary={<Typography>Pulls</Typography>} />
          </ListItemButton>
          <Divider sx={{ my: 1 }} />
          <ListItemButton
            onClick={() => {
              navigate(`/jobs`);
            }}
          >
            <ListItemIcon>
              <Inventory sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary={<Typography>Jobs</Typography>} />
          </ListItemButton>
          <Divider sx={{ my: 1 }} />
          <ListItemButton
            onClick={() => {
              navigate(`/inventory`);
            }}
          >
            <ListItemIcon>
              {/* <Inventory sx={{ color: "black" }} /> */}
              <img
                src={inventoryIcon}
                style={{ maxWidth: "25px", maxHeight: "25px" }}
              />
            </ListItemIcon>
            <ListItemText primary={<Typography>Inventory</Typography>} />
          </ListItemButton>
          <Divider sx={{ my: 1 }} />
          <ListItemButton
            onClick={() => {
              navigate(`/boneyard`);
            }}
          >
            <ListItemIcon>
              <img
                src={boneyardIcon}
                style={{ maxWidth: "25px", maxHeight: "25px" }}
              />
            </ListItemIcon>
            <ListItemText primary={<Typography>Boneyard</Typography>} />
          </ListItemButton>
          <Divider sx={{ my: 1 }} />
          <ListItemButton
            onClick={() => {
              navigate(`/committed`);
            }}
          >
            <ListItemIcon>
              {/* <AccessTimeIcon sx={{ color: "black" }} /> */}
              <img
                src={committedIcon}
                style={{ maxWidth: "25px", maxHeight: "25px" }}
              />
            </ListItemIcon>
            <ListItemText primary={<Typography>Committed</Typography>} />
          </ListItemButton>
          <Divider sx={{ my: 1 }} />
          <ListItemButton
            onClick={() => {
              navigate(`/tools`);
            }}
          >
            <ListItemIcon>
              {/* <SettingsIcon sx={{ color: "black" }} /> */}
              <img
                src={toolsIcon}
                style={{ maxWidth: "25px", maxHeight: "25px" }}
              />
            </ListItemIcon>
            <ListItemText primary={<Typography>Tools</Typography>} />
          </ListItemButton>
          {/* <ListItemButton
            onClick={() => {
              navigate(`/return`);
            }}
          >
            <ListItemIcon>
              <SettingsIcon sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary={<Typography>Return</Typography>} />
          </ListItemButton>
          <Divider sx={{ my: 1 }} /> */}
        </List>
        <Divider />
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            isSmallScreen
              ? "#ffffff"
              : theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mb: 4 }}>
          <Outlet />
        </Container>

        <SnackAlert
          open={openSnackAlert}
          setOpen={setOpenSnackAlert}
          severity={snackAlertSeverity}
          message={snackAlertMessage}
        />
      </Box>
    </Box>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
