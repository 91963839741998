import { Card } from "@mui/material";

const ListItem = ({
  title,
  details,
  image,
  onClick,
  emphasize,
  highlightColor,
  highlightOn,
  ...rest
}) => {
  // console.log("IMG", image);
  return (
    <Card
      onClick={onClick}
      style={{
        background: highlightOn
          ? highlightColor
            ? highlightColor
            : "#fff"
          : "#fff",
        // borderColor: highlightOn
        //   ? highlightColor
        //     ? highlightColor
        //     : "#fff"
        //   : "#fff",
        // borderWidth: highlightOn ? 1 : 0,
        // borderStyle: highlightOn ? "solid" : "none",
        borderRadius: 0,
        marginTop: 1,
        display: "flex",
        flexDirection: "row",
        padding: 8,
        cursor: "pointer",
      }}
      {...rest}
    >
      <div style={{ flex: 1 }}>
        <h4>{title}</h4>
        {details.map((detail, index) => (
          <div
            key={index}
            style={{
              fontSize: 12,
              color:
                emphasize && emphasize.includes(detail)
                  ? "#000"
                  : "rgba(0, 0, 0, 0.5)",
              fontWeight:
                emphasize && emphasize.includes(detail) ? "bold" : "normal",
            }}
          >
            {detail}
          </div>
        ))}
      </div>
      {image && (
        <div style={{ width: 100, padding: 12 }}>
          <img src={image} style={{ width: "100%", aspectRatio: 15 / 20 }} />
        </div>
      )}
    </Card>
  );
};

export default ListItem;
