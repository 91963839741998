import {
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import api from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import ListItem from "../../../component/ListItem";
import SearchAndPagination from "../../../component/SearchAndPagination";
import useSearchAndPagination from "../../../customHooks/useSearchAndPagination";
import Print from "../../../component/Print";
import QRCode from "react-qr-code";
import escapeCharacters from "../../../helpers/escapeCharacters";

const Tools = () => {
  const { authToken, userBranch } = useContext(AuthContext);
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [tools, setTools] = useState();
  const searchAndPagination = useSearchAndPagination(userBranch);
  const { search, location, page, setPages, setPage } = searchAndPagination;

  const [error, setError] = useState();

  const getAllTools = async () => {
    console.log("ALL THE OPTIONS ", searchAndPagination);
    try {
      const response = await api.get(
        `/tools?search=${escapeCharacters(
          search
        )}&location=${location}&pageSize=1000&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Tools", response);
      if (response.data.tools.tools) {
        setTools(response.data.tools.tools);
        setPages(response.data.tools.pages);
      }
    } catch (error) {
      console.log(error);
      setError(
        error.message
          ? error.message
          : "Something Went Wrong. Couldn't Load Resource."
      );
    }
  };

  useEffect(() => {
    getAllTools();
  }, [, search, location]);

  useEffect(() => {
    setTimeout(() => {
      getAllTools();
      setPage(1);
    }, 1500);
  }, [page]);

  if (!tools) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "70px",
        }}
      >
        <Typography variant="h5">{error ? error : "Loading..."}</Typography>
      </Box>
    );
  }

  return (
    <div style={{ padding: "22px", marginTop: "50px" }}>
      <Box>
        <Button
          sx={{ marginBottom: "5px", marginRight: "10px" }}
          variant="contained"
          onClick={() => navigate("/tools/requests")}
        >
          Tool Requests
        </Button>
        <Button
          sx={{ marginBottom: "5px" }}
          variant="contained"
          onClick={() => navigate("/tools/possession")}
        >
          Your Tools
        </Button>
      </Box>
      <SearchAndPagination
        stateHandlers={searchAndPagination}
        locationOptions={["SDL", "FLG"]}
      />
      {!isSmallScreen && (
        <Print>
          <Print.Trigger>
            <Button variant="contained">Print QR Codes</Button>
          </Print.Trigger>
          <Print.PrintableData>
            <div style={{ columns: 1, marginLeft: "15px" }}>
              {tools?.map((tool) => (
                <div
                  style={{
                    breakInside: "avoid",
                    breakBefore: "always",
                    breakAfter: "always",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "30%",
                      marginBottom: "0px",
                      width: "100px",
                    }}
                  >
                    <p style={{ marginBottom: "0px", textAlign: "center" }}>
                      {tool.name}
                    </p>
                  </div>

                  <QRCode
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    level="L" //'L' 'M' 'Q' 'H'
                    size={100} // display size
                    title={tool.name}
                    value={`${window.location.host}/tools/${tool.id}`}
                    style={{
                      marginLeft: "30%",
                      marginTop: "0px",
                      marginBottom: 0,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "30%",
                      marginBottom: "0px",
                      width: "100px",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "0px",
                        textAlign: "center",
                        marginTop: "0px",
                      }}
                    >
                      {tool.number}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </Print.PrintableData>
        </Print>
      )}
      {tools?.map((tool, i) => (
        <ListItem
          key={i}
          title={tool.name}
          details={[
            tool.branch,
            tool.brand,
            tool.model,
            tool.number,
            tool.serial,
          ]}
          // image={tool.external_picture}
          onClick={() => navigate(`/tools/${tool.id}`)}
        />
        // <Card>
        //   <Box>
        //     <Typography>{tool.name}</Typography>
        //   </Box>
        // </Card>
      ))}
    </div>
  );
};

export default Tools;
