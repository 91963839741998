import composePage from "../../HOCs/composePage";
import makePage from "../../HOCs/makePage";
import withConditionalFeedback from "../../HOCs/withConditionalFeedback";
import {
  Typography,
  TextField,
  Box,
  useTheme,
  useMediaQuery,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Button,
  Modal,
  InputLabel,
  Select,
  MenuItem,
  Card,
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useState } from "react";

const DamageReportView = ({
  data,
  report,
  dispatch,
  saveDamageReport,
  fetchEmployees,
  employees,
  setResponsibleUser,
  responsibleUser,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const assetName = data.name
    ? data.name
    : data.asset_name
    ? data.asset_name
    : data.equipment_name
    ? data.equipment_name
    : null;

  const handleFile = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    console.log(file);
    dispatch({ type: "UPDATE: IMAGE URLS", payload: url });
    dispatch({ type: "UPDATE: IMAGE BLOBS", payload: file });
  };

  const {
    getEmployees,
    setBranch,
    setPage,
    setPages,
    setSearch,
    branch,
    search,
    lastElement,
  } = fetchEmployees;
  const branchOptions = ["SDL", "FLG"];
  const [employeeModal, setEmployeeModal] = useState(false);
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleBranch = (e) => {
    setBranch(e.target.value);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography
          variant="overline"
          fontWeight="600"
          fontSize="1rem"
          textAlign={"center"}
        >
          Damage Report
        </Typography>
        {assetName && (
          <Typography variant="overline" textAlign={"center"}>
            {assetName}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {responsibleUser.responsibleUserName && (
            <Typography variant="overline" textAlign={"center"}>
              responsible:{" "}
              <Typography
                variant="overline"
                textAlign={"center"}
                fontWeight="600"
                sx={{ textDecoration: "underline" }}
              >
                {responsibleUser.responsibleUserName}
              </Typography>
            </Typography>
          )}
          <Button
            variant="outlined"
            size="small"
            sx={{ marginBottom: "1rem" }}
            onClick={() => setEmployeeModal(true)}
          >
            {responsibleUser.responsibleUserName
              ? "change?"
              : "Who is Responsible?"}
          </Button>
        </Box>
        <Box sx={{ width: "25rem", padding: ".5rem" }}>
          <TextField
            label="Description..."
            multiline
            rows={4}
            fullWidth
            sx={{ backgroundColor: "white", mb: "1.5rem" }}
            value={report.notes}
            onChange={(e) =>
              dispatch({ type: "UPDATE: NOTES", payload: e.target.value })
            }
          />
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={report.assetStatus}
              onChange={(e) =>
                dispatch({ type: "UPDATE: STATUS", payload: e.target.value })
              }
            >
              <FormControlLabel
                value="useable"
                control={<Radio />}
                label="useable"
              />

              <FormControlLabel
                value="out-of-commission"
                control={<Radio />}
                label="out of commission"
              />
            </RadioGroup>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              mt: "2rem",
            }}
          >
            <Button
              variant="contained"
              startIcon={<AddAPhotoIcon />}
              component="label"
            >
              {" "}
              photo
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                capture="environment"
                onChange={handleFile}
              />
            </Button>
          </Box>
        </Box>
        <Box>
          {report.imgUrls.map((src) => (
            <img
              key={src}
              src={src}
              style={{
                minHeight: "100px",
                maxHeight: "100px",
                minWidth: "100px",
                maxWidth: "100px",
                marginRight: "3px",
                boxShadow: "5px 5px 5px #8d8f8e",
              }}
            />
          ))}
        </Box>
        <Button
          variant="contained"
          fullWidth={isSmallScreen}
          sx={{ marginTop: "3rem" }}
          onClick={saveDamageReport}
        >
          Submit
        </Button>
      </Box>
      <Modal open={employeeModal} onClose={() => setEmployeeModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            // maxHeight: "85%",
          }}
        >
          <Box
            sx={{
              marginBottom: "1em",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <TextField
              variant="outlined"
              label="search..."
              size="small"
              value={search}
              onChange={handleSearch}
            />
            <Box sx={{ minWidth: 120, marginLeft: "1em" }}>
              <FormControl>
                <InputLabel id="select-label">Branch</InputLabel>
                <Select
                  size="small"
                  labelId="select-label"
                  id="select"
                  value={branch}
                  label="Branch"
                  onChange={handleBranch}
                >
                  {branchOptions.map((br) => (
                    <MenuItem key={br} value={br}>
                      {br}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ height: "20rem", overflowY: "scroll" }}>
            <Card
              sx={{ padding: "1rem", backgroundColor: "#ededed" }}
              onClick={() => {
                setResponsibleUser({ id: null, name: null });
                setEmployeeModal(false);
              }}
              key={"Nobody Is Responsible"}
            >
              Nobody Is Responsible
            </Card>
            {employees.length > 0 &&
              employees.map((em, i) => {
                if (employees.length === i + 1) {
                  return (
                    <Card
                      key={em.full_name}
                      ref={lastElement}
                      sx={{ padding: "1rem" }}
                      onClick={() => {
                        setResponsibleUser(em);
                        setEmployeeModal(false);
                      }}
                    >
                      {em.full_name}
                    </Card>
                  );
                } else {
                  return (
                    <Card
                      key={em.full_name}
                      sx={{ padding: "1rem" }}
                      onClick={() => {
                        setResponsibleUser(em);
                        setEmployeeModal(false);
                      }}
                    >
                      {em.full_name}
                    </Card>
                  );
                }
              })}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default composePage(makePage, withConditionalFeedback)(DamageReportView);
