import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import Jobcard from "../Jobs/JobCard";
import useSearchAndPagination from "../../../customHooks/useSearchAndPagination";
import SearchAndPagination from "../../../component/SearchAndPagination";
import escapeCharacters from "../../../helpers/escapeCharacters";
import PageContent from "../../../component/PageContent";
import ListItem from "../../../component/ListItem";

const Staged = () => {
  const navigate = useNavigate();
  const { authToken, userBranch } = useContext(AuthContext);

  const [openJobs, setOpenJobs] = useState([]);
  const [closedJobs, setClosedJobs] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  const searchAndPagination = useSearchAndPagination(userBranch);
  const { search, location, page, setSearch, setPage, pages, setPages } =
    searchAndPagination;

  const observer = useRef();
  const lastElement = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page < pages) {
          console.log("visible");
          setPage((curr) => curr + 1);
        }
      });
      if (node) observer.current.observe(node);
      // console.log("NODE: ", node);
    },
    [isLoading, page < pages]
  );

  // const getOnlyUniqueObjects = (objectArray) =>
  //   [...new Set(objectArray.map((o) => JSON.stringify(o)))].map((o) =>
  //     JSON.parse(o)
  //   );

  const getLocations = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      console.log("Get Locations");
      const response = await InventoryTracking.get(`/locations`, data);
      console.log(response.data);
      setLocations(response.data.locations);
    } catch (error) {
      console.log(error);
    }
  };

  const getJobs = async () => {
    try {
      const response = await InventoryTracking.get(
        `staged-jobs?search=${escapeCharacters(
          search
        )}&location=${location}&pageSize=25&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("getJobs ", response.data);
      const jobs = response.data;
      if (page > 1) {
        setOpenJobs((prev) => {
          return [...prev, ...jobs.stagedJobs.jobs];
        });
      } else {
        setOpenJobs((prev) => {
          return [...jobs.stagedJobs.jobs];
        });
      }

      setPages(jobs.stagedJobs.pages);
      // setClosedJobs(jobs.closedJobs.jobs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    getLocations(controller);
    getJobs();

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [location, page]);

  useEffect(() => {
    const controller = new AbortController();

    setTimeout(() => {
      getLocations(controller);
      getJobs();
      setPage(1);
    }, 1500);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [search]);

  const searchHandler = (e) => {
    console.log(e.target.value);
    setSearch(e.target.value);
  };

  return (
    <PageContent>
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Staged
      </h2>
      <SearchAndPagination
        stateHandlers={searchAndPagination}
        locationOptions={locations.map((loc) => loc.location)}
      />
      <div>
        {openJobs.length > 0 &&
          openJobs.map((job, index) => {
            if (openJobs.length === index + 1) {
              return (
                <div
                  key={index}
                  ref={lastElement}
                  onClick={() => navigate(`/jobs/${job.id}/staged`)}
                >
                  <ListItem title={job.job_name} details={[job.status_name]} />
                </div>
              );
            } else {
              return (
                <div
                  key={index}
                  onClick={() => navigate(`/jobs/${job.id}/staged`)}
                >
                  <ListItem title={job.job_name} details={[job.status_name]} />
                </div>
              );
            }
          })}
      </div>

      {/* <Jobcard
        ref={lastElement}
        sx={{
          marginTop: "10px",
          marginBottom: "10px",
          borderTop: "1px solid black",
        }}
        jobs={openJobs}
        // heading="Open Jobs"
        open={true}
        navParam="/staged"
      /> */}
      {/* <Jobcard
        sx={{
          marginTop: "10px",
          marginBottom: "10px",
          borderTop: "1px solid black",
        }}
        jobs={closedJobs}
        heading="Closed Jobs"
        open={false}
      /> */}
    </PageContent>
  );
};

export default Staged;
