import { useTheme } from "@emotion/react";
import { Card, Typography, Box, useMediaQuery, Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Tile = ({ title = "", path = "", badgeCount = 0 }) => {
  const theme = useTheme();
  console.log("THEME", theme);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Badge color="secondary" badgeContent={badgeCount} max={25}>
        <Card
          raised={true}
          sx={{
            width: isSmallScreen ? "140px" : "350px",
            height: "115px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px",
            cursor: "pointer",
            backgroundColor: theme.palette.primary.main,
            borderRadius: "15px",
            justifyContent: "center",
          }}
          onClick={() => navigate(path)}
        >
          <Typography
            variant="h6"
            // fontWeight={300}
            color="white"
            textAlign={"center"}
          >
            {title.toUpperCase()}
          </Typography>
        </Card>
      </Badge>
      {/* <div
        style={{
          backgroundColor: "green",
          width: "35px",
          height: "35px",
          textAlign: "center",
          borderRadius: "50%",
          position: "relative",
        }}
      >
        5
      </div> */}
    </>
  );
};
export default Tile;
