import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthContext";
import { ThemeProvider } from "@emotion/react";
import { TopNavProvider } from "./context/TopNavContext";
import { createTheme, CssBaseline } from "@mui/material";
import { GrabsProvider } from "./context/GrabsContext";
import { PullRequestProvider } from "./context/PullRequestContext";
import { SnackAlertProvider } from "./context/SnackAlertContext";
import { AssetContextProvider } from "./context/AssetContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Modify default app theme here: https://mui.com/material-ui/customization/default-theme/
const appTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#202A44",
    },
    // secondary: {
    //   main: "#0d47a1",
    //   contrastText: "#FFFFFF",
    // },
    secondary: {
      main: "#C7AD87",
      // main: "#e49908",
      contrastText: "#000",
    },
  },
  props: {
    MuiAppBar: {
      color: "transparent",
    },
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <AuthProvider>
        <SnackAlertProvider>
          <GrabsProvider>
            <PullRequestProvider>
              <TopNavProvider>
                <AssetContextProvider>
                  <App />
                </AssetContextProvider>
              </TopNavProvider>
            </PullRequestProvider>
          </GrabsProvider>
        </SnackAlertProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
