import {
  Box,
  TextField,
  MenuItem,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const SearchAndPagination = ({
  stateHandlers,
  locationOptions = [],
  showCategory = false,
  categoryOptions = [],
}) => {
  const {
    search,
    setSearch,
    location,
    setLocation,
    page,
    setPage,
    pages,
    limit,
    category,
    setCategory,
    loading,
    setLoading,
  } = stateHandlers;

  const theme = useTheme();

  const breakPoint = useMediaQuery(theme.breakpoints.down("sm"));
  const handlePage = (direction) => () => {
    setLoading(true);
    switch (direction) {
      case "INC":
        if (page === pages || pages === 0) {
          setLoading(false);
          return;
        }
        setPage(page + 1);
        setLoading(false);
        break;
      case "DEC":
        if (page - 1 === 0) {
          setPage(1);
          setLoading(false);
        } else {
          setPage(page - 1);
          setLoading(false);
        }
        break;
      default:
        setLoading(false);
        break;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: breakPoint ? "column" : "row",
        marginTop: "7px",
      }}
    >
      <TextField
        id="search"
        label="Search..."
        variant="outlined"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        size="small"
        sx={{
          marginRight: "10px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: breakPoint ? "7px" : "0px",
          marginBottom: breakPoint ? "7px" : "0px",
          justifyContent: breakPoint ? "flex-end" : "center",
        }}
      >
        <TextField
          select
          id="branchSelect"
          value={location}
          label="Branch"
          onChange={(e) => setLocation(e.target.value)}
          size="small"
          sx={{
            minWidth: "100px",
            marginRight: "10px",
            // display: breakPoint ? "none" : "",
          }}
        >
          {locationOptions.map((branch, i) => (
            <MenuItem key={i} value={branch}>
              {branch}
            </MenuItem>
          ))}
        </TextField>
        {showCategory && (
          <TextField
            select
            id="categorySelect"
            value={category}
            label="Category"
            onChange={(e) => setCategory(e.target.value)}
            size="small"
            sx={{
              minWidth: "100px",
              marginRight: "10px",
            }}
          >
            {categoryOptions.map((cat, i) => (
              <MenuItem key={i} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
    </Box>
  );
};
export default SearchAndPagination;
