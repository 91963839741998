import { useContext, useState, useEffect } from "react";
import { AuthContext } from "./context/AuthContext";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/admin/Dashboard";
import Inventory from "./pages/admin/Inventory/Inventory";
import Boneyard from "./pages/admin/Boneyard/Boneyard";
import Committed from "./pages/admin/Committed/Committed";
import Tools from "./pages/admin/Tools/Tools";
import Return from "./pages/admin/Return/Return";
import InventoryItemDetails from "./pages/admin/Inventory/InventoryItemDetails";
import CommittedDetails from "./pages/admin/Committed/CommittedDetails";
import BoneyardItemDetails from "./pages/admin/Boneyard/BoneyardItemDetails";
import ToolRequests from "./pages/admin/Tools/ToolRequests";
import ToolsInUsersPossession from "./pages/admin/Tools/ToolsInUsersPossession";
import { PrintAllInventoryItems } from "./pages/admin/Inventory/PrintAll";
import ReturnDetails from "./pages/admin/Return/ReturnDetails";

import ToolDetail from "./pages/admin/Tools/ToolDetail";
import Jobs from "./pages/admin/Jobs/Jobs";
import HomePage from "./pages/admin/HomePage/HomePage";
import JobDetails from "./pages/admin/Jobs/JobDetails";
import DeliverFromStaging from "./pages/admin/Jobs/DeliverFromStaging";
import CreateStockout from "./pages/admin/Pulls/CreateStockout";
import PullDetails from "./pages/admin/Pulls/PullDetails";
import PullRequests from "./pages/admin/Pulls/PullRequests";
import PendingPOs from "./pages/admin/POs/PendingPOs";
import Staged from "./pages/admin/Staged/Staged";
import CreatePullRequest from "./pages/admin/Pulls/CreatePullRequest";
import DailyStockouts from "./pages/admin/Pulls/DailyStockouts";
import InventoryTransactions from "./pages/admin/Inventory/InventoryTransactions";
import BoneyardTransactions from "./pages/admin/Boneyard/BoneyardTransactions";
import CommittedTransactions from "./pages/admin/Committed/CommittedTransactions";
import StagedTransaction from "./pages/admin/Staged/StagedTransaction";

// ASSET MANAGEMENT
import EmployeeListComponent from "./pages/admin/AssetManagement/EmployeeAssignment/EmployeesListComponent";
import AssetListComponent from "./pages/admin/AssetManagement/Assets/AssetList/AssetListComponent";
import AssetDetailComponent from "./pages/admin/AssetManagement/Assets/AssetDetail/AssetDetailComponent";
import RequestsComponent from "./pages/admin/AssetManagement/Assets/AssetRequests/RequestsComponent";
import AssignedAssetsComponent from "./pages/admin/AssetManagement/Assets/AssignedAssets/AssignedAssetsComponent";
import DamageReportComponent from "./pages/admin/AssetManagement/Assets/DamageReport/DamageReportComponent";
import DamageComponent from "./pages/admin/AssetManagement/Assets/AssetDetail/DamageReports/DamageComponent";

import Employees from "./pages/admin/AssetManagement/Assets/Employees/Employees";
import PrintAssetList from "./pages/admin/AssetManagement/Assets/AssetList/PrintAssetList";
import PrintBehmerStickers from "./pages/admin/AssetManagement/Assets/AssetList/PrintBehmerStickers";
import PrintPullDetails from "./pages/admin/Pulls/PrintPullDetails";

const App = () => {
  const { authToken } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={authToken === null ? <Login /> : <Dashboard />}
        >
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          {/* :section MATCHING: pos, pull-requests, staged, delivered, committed */}

          <Route path="/tools" element={<Tools />} />
          <Route path="/tools/:id" element={<ToolDetail />} />
          <Route path="/tools/requests" element={<ToolRequests />} />
          <Route path="/staged" element={<Staged />} />
          <Route
            path="/tools/possession"
            element={<ToolsInUsersPossession />}
          />
          {/*vvvvv NEW ASSET MANAGEMENT ROUTES vvvvv*/}
          <Route path="/assets/:type" element={<AssetListComponent />} />
          <Route path="/assets/:type/:id" element={<AssetDetailComponent />} />
          <Route path="/assets/requests" element={<RequestsComponent />} />
          <Route
            path="/assets/assigned"
            element={<AssignedAssetsComponent />}
          />
          <Route
            path="/assets/:type/:id/assign/"
            element={<EmployeeListComponent />}
          />
          <Route
            path="/assets/:type/:id/file-damage-report"
            element={<DamageReportComponent />}
          />
          <Route
            path="/assets/:type/:id/damage-reports"
            element={<DamageComponent />}
          />
          <Route path="/employees" element={<Employees />} />
          <Route
            path="/assets/:type/:branch/qr-codes"
            element={<PrintAssetList />}
          />
          <Route path="/behmer-stickers" element={<PrintBehmerStickers />} />
          {/*^^^^^ NEW ASSET MANAGEMENT ROUTES ^^^^^*/}
        </Route>
        <Route path="*" element={<div>404 error</div>} />
        <Route path="/error401" element={<div>401 error</div>} />
        <Route path="/error403" element={<div>403 error</div>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
