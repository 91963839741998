import {
  Box,
  Button,
  Card,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Select,
  FormControl,
  InputLabel,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import composePage from "../../HOCs/composePage";
import makePage from "../../HOCs/makePage";
import withSearchBar from "../../HOCs/withSearchBar";
import withConditionalFeedback from "../../HOCs/withConditionalFeedback";
import useAssetManagers from "../../../../../customHooks/useAssetManagers";

const AssetListView = ({
  data,
  callback,
  assetType,
  branch,
  handleFilter,
  filter,
  search,
  setSearch,
  branchOptions = ["SDL", "FLG"],
  setBranch,
  assetTypeOptions,
  setAssetType,
}) => {
  console.log(data);
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleBranch = (e) => {
    setBranch(e.target.value);
  };

  const handleAssetType = (e) => {
    setAssetType(e.target.value);
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAssetManager } = useAssetManagers();
  const parseAssetBasedOnType =
    (type) =>
    ({ asset }) => {
      switch (type) {
        case "tool":
        case "ladder":
        case "equipment":
          return (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="overline" sx={styles.toolName}>
                {asset.name}
              </Typography>
              {asset.active == 1 && (
                <Typography
                  variant="overline"
                  sx={{
                    lineHeight: "1rem",
                    mb: "1rem",
                    color: asset.currently_assigned_to ? "#064ec9" : "#06c916",
                    fontWeight: "600",
                  }}
                >
                  {asset.currently_assigned_to
                    ? `${asset.currently_assigned_to}`
                    : `AVAILABLE`}
                </Typography>
              )}
              {asset.active !== 1 && (
                <>
                  <Typography
                    variant="overline"
                    sx={{
                      lineHeight: "1rem",
                      mb: "1rem",
                      color: "#ed1202",
                      fontWeight: "600",
                    }}
                  >
                    INACTIVE
                  </Typography>
                  <Typography>{asset.availability}</Typography>
                </>
              )}

              <Typography variant="caption">{asset.branch}</Typography>
              <Typography variant="caption">{asset.number}</Typography>
              <Typography variant="caption">{asset.serial}</Typography>
            </Box>
          );
        case "safety-equipment":
        case "safety-gear":
          return (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="overline" sx={styles.toolName}>
                {type === "safety-equipment"
                  ? asset.equipment_name
                  : asset.gear_type}
              </Typography>
              <Typography
                variant="overline"
                sx={{
                  lineHeight: "1rem",
                  mb: "1rem",
                  color: asset.currently_assigned_to ? "#064ec9" : "#06c916",
                  fontWeight: "600",
                }}
              >
                {asset.currently_assigned_to
                  ? `${asset.currently_assigned_to}`
                  : `AVAILABLE`}
              </Typography>
              <Typography variant="caption">{asset.branch}</Typography>
              <Typography variant="caption">{asset.manufacturer}</Typography>
              <Typography variant="caption">{asset.serial}</Typography>
            </Box>
          );
        default:
          break;
      }
    };
  const AssetDetails = parseAssetBasedOnType(assetType);

  const pageTitle =
    assetType === "tool"
      ? "TOOLS"
      : assetType === "ladder"
      ? "LADDERS"
      : assetType === "equipment"
      ? "EQUIPMENT"
      : assetType === "safety-equipment"
      ? "SAFETY EQUIPMENT"
      : "SAFETY GEAR";

  const listTypeOptions = [
    {
      name: "Main List",
      value: "main",
      nav: `assets/${assetType}`,
    },
    {
      name: `My ${pageTitle[0].toUpperCase()}${pageTitle
        .slice(1)
        .toLowerCase()}`,
      value: "assigned",
      nav: `/assets/assigned`,
    },
    {
      name: "Transfer Requests",
      value: "transfer-requests",
      nav: `/assets/requests`,
    },
    {
      name: "By Employee",
      value: "employee-list",
      nav: `/employees`,
    },
  ];

  const onSelectListType = (e) => {
    const option = e.target.value;
    navigate(option.nav);
  };

  return (
    <>
      <Box
        sx={{
          marginBottom: "1em",
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <TextField
          variant="outlined"
          label="search..."
          size="small"
          value={search}
          onChange={handleSearch}
        />
        <Box
          sx={{
            minWidth: 120,
            marginLeft: isSmallScreen ? "0" : "1em",
            marginTop: isSmallScreen ? "1em" : "0",
            display: "flex",
            gap: "1rem",
          }}
        >
          <FormControl>
            <InputLabel id="select-label">Branch</InputLabel>
            <Select
              size="small"
              labelId="select-label"
              id="select"
              value={branch}
              label="Branch"
              onChange={handleBranch}
            >
              {branchOptions.map((br) => (
                <MenuItem key={br} value={br}>
                  {br}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="asset-select">Asset Type</InputLabel>
            <Select
              size="small"
              labelId="asset-select"
              id="select-asset"
              value={assetType}
              label="Asset"
              onChange={handleAssetType}
            >
              {assetTypeOptions.map((ass) => (
                <MenuItem key={ass.value} value={ass.value}>
                  {ass.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            size="small"
            select
            value={listTypeOptions[0]}
            onChange={onSelectListType}
            label="List Type"
          >
            {listTypeOptions.map((opt, i) => (
              <MenuItem value={opt} key={i}>
                {/* <Typography variant="overline">{opt.name}</Typography> */}
                {opt.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <Box>
        <Box sx={{ marginBottom: "1em", display: "flex", gap: "1rem" }}>
          {!isSmallScreen && (
            <Button
              size="small"
              variant="contained"
              onClick={() =>
                navigate(`/assets/${assetType}/${branch}/qr-codes`)
              }
            >
              Print QR Codes
            </Button>
          )}
          {!isSmallScreen && (
            <Button
              size="small"
              variant="contained"
              onClick={() => navigate(`/behmer-stickers`)}
            >
              Print Behmer Stickers
            </Button>
          )}
        </Box>
        <Typography textAlign={"center"} mb={"1em"} variant="h5">
          {pageTitle}
        </Typography>
        <Box>
          <TextField
            select
            value={filter}
            defaultValue="All"
            onChange={handleFilter}
            label={"Availability"}
            size="small"
            sx={{ marginBottom: "1rem" }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="assigned">Assigned</MenuItem>
            <MenuItem value="available">Available</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </TextField>
          {data.map((asset, index) => {
            if (data.length === index + 1) {
              return (
                <Card
                  ref={callback}
                  sx={styles.card}
                  key={`${asset.serial}${index}`}
                  onClick={() => navigate(`/assets/${assetType}/${asset.id}`)}
                >
                  <AssetDetails asset={asset} />
                  <Box>
                    {asset.external_picture && (
                      <img
                        src={asset.external_picture}
                        alt="No Image Provided"
                        width="100px"
                        height="100px"
                      />
                    )}
                  </Box>
                </Card>
              );
            } else {
              return (
                <Card
                  sx={styles.card}
                  key={`${asset.serial}${index}`}
                  onClick={() => navigate(`/assets/${assetType}/${asset.id}`)}
                >
                  <AssetDetails asset={asset} />
                  <Box>
                    {asset.external_picture && (
                      <img
                        src={asset.external_picture}
                        // src="https://inventory-tracking-app.s3.us-east-2.amazonaws.com/public/tools/T-003.jpg"
                        alt="No Image Provided"
                        width="100px"
                        height="100px"
                      />
                    )}
                  </Box>
                </Card>
              );
            }
          })}
        </Box>
      </Box>
    </>
  );
};
export default composePage(
  makePage,
  // withSearchBar,
  withConditionalFeedback
)(AssetListView);

const styles = {
  card: {
    padding: ".5em",
    marginBottom: ".5em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  toolName: {
    fontWeight: "600",
    lineHeight: "1rem",
  },
};
