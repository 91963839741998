import Tile from "./Tile";
import { Box } from "@mui/material";
import useBadgeCounts from "./useBadgeCounts";

const HomePage = () => {
  const { counts, loading } = useBadgeCounts();
  console.log(counts);

  const tiles = [
    {
      title: "Asset Mgmt.",
      path: "/assets/tool",
      count: 0,
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 10,
        }}
      >
        {tiles.map((t, index) => (
          <Box
            key={index}
            sx={{
              flexBasis: "50%",
              display: "flex",
              justifyContent: index % 2 == 0 ? "end" : "start",
              padding: "10px",
            }}
          >
            <Tile title={t.title} path={t.path} badgeCount={t.count} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default HomePage;
