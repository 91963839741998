import { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as Yup from "yup";
import InventoryTracking from "../../api/InventoryTracking";
import QRCode from "react-qr-code";
import PrintableQRCode from "../../component/PrintableQRCode";
import behmerLogo from "../../assets/black_behmer_logo.png";

export default function Login() {
  let navigate = useNavigate();
  const { setAuthentication } = useContext(AuthContext);
  const [inValidRole, setInvalidRole] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const login = async (requestBody) => {
    setIsLoading(true);
    setErrorMsg(null);
    console.log("Login request made to " + `/login`);
    console.log(requestBody);

    try {
      const response = await InventoryTracking.post(`/login`, requestBody);
      console.log(response);
      setIsLoading(false);
      console.log(response.data);
      const accessToken = response.data.accessToken;
      setAuthentication(accessToken);
      localStorage.setItem("accessToken", accessToken);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        setErrorMsg(error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        setErrorMsg("Internal Server Error");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&?*])(?=.{8,})/;

  //The hashed password marco gave me is 'password1' which doesn't pass. We'll have to make sure to use whatever password requirements Marco is using in Current BRM
  const DEVELOPMENT_PASSWORD_REGEX = /(.*)/;
  const logInValidationSchema = Yup.object({
    username: Yup.string().required("Username Required"),
    password: Yup.string()
      .required("Password Required")
      .max(
        20,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .matches(
        DEVELOPMENT_PASSWORD_REGEX,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: logInValidationSchema,
    onSubmit: (values) => {
      login(values);
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          background: "white",
          padding: "16px",
        }}
      >
        <img
          src={behmerLogo}
          style={{ width: "60%", height: "10%", marginTop: "15%" }}
        />
      </div>
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isSmallScreen ? (
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
        ) : (
          <PrintableQRCode
            url={`${window.location.host}/`}
            displaySize={50}
            subtext={"Material App Login Page"}
            printSize={100}
          />
        )}

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
            autoComplete="username"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            autoComplete="current-password"
          />

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          {errorMsg && (
            <Box>
              <Typography color={"red"}>{errorMsg}</Typography>
            </Box>
          )}
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
            >
              Sign In
            </Button>
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Box>
        {inValidRole && (
          <Box sx={{ color: "#cf0404" }}>
            You Are Not Authorized To Visit This Page.
          </Box>
        )}
      </Box>
    </Container>
  );
}
